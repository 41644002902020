<template>
  <div
      id="app"
      class="h-100"
      :class="[skinClasses]"
  >
    <app-tour name="app_tour" :steps="steps"/>
    <component :is="layout">
      <router-view/>
    </component>
<!--    <Sockets/>-->

    <b-modal
        id="modal-phone-form"
        :no-close-on-backdrop="true"
        cancel-variant="outline-secondary"
        :hide-footer="true"
        centered
        size="sm"
        title="Ավելացնել հեռախոսահամար"
        :is-valid="false"
    >
      <b-form>
        <b-form-group>
          <b-alert
              variant="primary"
              show
          >
            <div class="alert-body">
              <span v-if="!inputCodeStatus && user">Հարգելի {{user.name}}, խնդրում ենք մուտքագրել Ձեր հառախոսահամարը</span>
              <span v-if="inputCodeStatus">{{ inputCodeMessage }}</span>
            </div>
          </b-alert>
        </b-form-group>
        <b-form-group v-if="phoneAction !== 'verifyCode'">
          <MazPhoneNumberInput
              id="phone"
              v-model="phone_number"
              autocomplete="new-phone-op"
              name="phone"
              :only-countries="['AM']"
              :default-country-code="'AM'"
              :no-country-selector="true"
              :translations="phoneTranslations"
              left-icon-name="phone"
              @update="phoneData = $event"
          />
        </b-form-group>
        <b-form-group v-if="phoneAction === 'inputPhone'" class="float-right text-right">
          <b-button @click="checkPhone" variant="success" :disabled="!this.phoneData.isValid">Ստուգել</b-button>
        </b-form-group>
        <!-- Recaptcha -->
        <template v-if="phoneAction === 'verifyCode'">
          <b-form-group>
            <b-form-input
                v-model="verification_code"
                id="name"
                type="text"
                placeholder="Հաստատման կոդ"
                v-mask="'######'"
            />
          </b-form-group>
          <b-form-group class="float-right text-right">
            <b-button @click="confirmPhone" variant="success" :disabled="!this.phoneData.isValid">Հաստատել</b-button>
          </b-form-group>
        </template>

      </b-form>

    </b-modal>

  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { BModal, BForm, BFormGroup, BFormInput, BButton, BAlert, } from 'bootstrap-vue'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import AppTour from '@core/components/app-tour/AppTour.vue'
import Sockets from '@/sockets/Sockets'
import { VueTyper } from 'vue-typer'
const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
import firebase from '@core/firebase/firebase'

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    AppTour,
    Sockets,
    VueTyper,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BAlert,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  data() {
    return {
      isFirstTime: false,
      phone_number: null,
      phoneData: {
        isValid: false,
      },
      phoneTranslations: {
        countrySelectorLabel: 'Կոդ',
        countrySelectorError: 'Ընտրեք երկիրը',
        phoneNumberLabel: 'Հեռախոսահամար',
        example: 'Օրինակ:',
      },
      phoneAction: 'inputPhone',
      verification_code: null,
      codeResult: null,
      inputCodeStatus: false,
      inputCodeMessage: 'Խնդրում ենք մուտքագրել SMS-ով ստացված հաստատման կոդը։',
    }
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    steps() {
      return this.$store.state.tourSteps
    },
    user() {
      return this.$store.state.user
    }
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement)
          .value
          .trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement)
          .value
          .slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
    this.$store.dispatch('checkServerStatus')
        .then((response => {
          const app_version = localStorage.getItem('Inspect_Version')
          const server_version = response.data.APP_VERSION
          if (this.user) {
            if (server_version !== app_version) {
              localStorage.removeItem('infoForMobileAdd');
              localStorage.setItem('Inspect_Version', server_version)
              window.location.reload(true)
            }
          }
          else {
            localStorage.setItem('Inspect_Version', server_version)
            if (app_version && server_version !== app_version) {
              window.location.reload(true)
            }
          }
        }))
        .catch(() => {
          if (this.$store.state.time && typeof this.$store.state.time.diff !== 'undefined' && this.$store.state.time.diff) {
            if (this.$store.state.time.diff > 0) {
              if (this.$store.state.user) {
                this.$store.commit('SIGN_OUT')
                location.reload()
              }
            }
          } else {
            this.$vs.loading.close()
            this.$swal({
              title: 'Զգուշացում!',
              text: 'Կապը սերվերի հետ բացակայում է։',
              icon: 'warning',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
                .then(() => {
                  // this.$store.dispatch('setTourSteps', [
                  //   {
                  //     target: '#onlineCount',
                  //     header: {
                  //       title: 'Online',
                  //     },
                  //     content: 'Online գրանցված քանակ։',
                  //   },
                  //   {
                  //     target: '#offlineCount',
                  //     header: {
                  //       title: 'Offline',
                  //     },
                  //     content: 'Offline գրանցված քանակ։',
                  //   },
                  // ])
                  //     .then(() => {
                  //       this.$tours.vuexyTour.start()
                  //     })
                })
          }
        })
  },
  setup() {
    const {
      skin,
      skinClasses
    } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
  watch: {
    user: {
      deep: true,
      handler(to, from) {
        if (to) {
          if (to.twoFactor && !to.phone) {
            this.askPhone()
          }
        }
      },
    }
  },
  mounted() {

    this.$root.$on('checkPermission', (data) => {
      return this.checkPermission(data)
    })

    this.$root.$on('getError', (error) => {
      this.getErrors(error)
    })

    this.$root.$on('showError', (message) => {
      this.showError(message)
    })

  },
  methods: {

    getErrors(error) {
      if (typeof error.response.data.errors !== 'undefined') {
        const item = error.response.data.errors[Object.keys(error.response.data.errors)[0]]
        if (item.length > 0) {
          this.showError(item[0])
        } else {
          this.showError(item.message)
        }

      } else {
        this.showError(error.response.data.message)
      }
    },

    showError(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'EditIcon',
          variant: 'danger',
        },
      })
    },

    checkPermission(data) {
      return false
    },

    checkIsFirstTime() {
      const isFirstTime = this.getCookie('isFirstTime')
      if (!isFirstTime) {
        this.isFirstTime = true
        this.createLogo()
      }
    },

    createLogo() {
      this.createCookie('isFirstTime', true)
          .then(() => {
            setTimeout( () => {
              this.isFirstTime = false
            }, 7000);
          })
    },

    getCookie(name) {
      const nameEQ = name + '='
      const ca = document.cookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
      }
      return null
    },

    createCookie(name, value) {
      return new Promise((resolve, reject) => {
        try {
          const date = new Date()
          date.setTime(date.getTime() + (24 * 60 * 60 * 1000))
          const expires = '; expires=' + date.toGMTString()
          document.cookie = name + '=' + value + '; expires=' + expires + '; path=/'
          resolve(value)
        } catch {
          reject({ status: false })
        }
      })

    },

    askPhone() {
      this.$bvModal.show('modal-phone-form')
    },

    checkPhone() {
      this.$vs.loading()
      this.$store.dispatch('checkPhone', this.phone_number)
      .then(response => {
        this.inputCodeStatus = true
        this.phoneAction = 'verifyCode'

        this.$vs.loading.close()
      })
      .catch(error => {
        this.$vs.loading.close()
        this.getErrors(error)
      })
    },
    confirmPhone() {
      this.$vs.loading()
      this.$store.dispatch(`addVerifiedPhone`, {
        phone: this.phone_number,
        code: this.verification_code,
      })
          .then(() => {
            this.$vs.loading.close()
            this.$bvModal.hide('modal-phone-form')
          })
          .catch(error => {
            this.getErrors(error)
            this.$vs.loading.close()
          })
    }

  },

}
</script>

<style lang="scss">
.govLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  text-align: center;

  img {
    &.logo {
      width: 200px;
    }
  }
}
.vue-typer {
  font-weight: bold;
  font-size: 1.8rem;
}
.typewriter {
  margin: 1rem;
}
#modal-phone-form {
  button[aria-label="Close"] {
    display: none;
  }
}
#recaptcha-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
