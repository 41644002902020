import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCircleNotch,
  faBuilding,
  faUsers,
  faCogs,
  faGlobeAsia,
  faSearchLocation,
  faRoad,
  faMapMarkerAlt,
  faEdit,
  faTrashAlt,
  faPowerOff,
  faCar,
  faCarSide,
  faTruckMoving,
  faTruckPickup,
  faTrailer,
  faPalette,
  faGasPump,
  faUserFriends,
  faTools,
  faExclamationTriangle,
  faPlusCircle,
  faUser,
  faPhoneSquareAlt,
  faPassport,
  faTimesCircle,
  faAd,
  faCalendarCheck,
  faBarcode,
  faListOl,
  faExchangeAlt,
  faCheckDouble,
  faClipboardList,
  faTimes,
  faCheckCircle,
  faPlus,
  faDotCircle,
  faSave,
  faFileWord,
  faPrint,
  faCalendarAlt,
  faSignInAlt,
  faCarCrash,
  faBurn,
  faQrcode,
  faKey,
  faUserEdit,
  faSortNumericUp,
  faChartArea,
  faHistory,
  faDownload,
  faBan,
  faInfoCircle,
  faSearch,
  faEnvelope,
  faCode,
  faTerminal,
  faBars,
  faHome,
  faMapSigns,
  faPhoneAlt,
  faMoneyCheckAlt,
  faCreditCard,
  faPortrait,
  faFolderPlus,
  faPaperclip,
  faCheck,
  faBell,
  faPaste,
  faUndo,
  faUserCog,
  faSearchPlus,
  faPlusSquare,
  faBackspace,
  faQuestionCircle,
  faReceipt,
  faSearchDollar,
  faSearchMinus,
  faTabletAlt,
  faLaptopCode,
  faLink,
  faSortNumericDown,
  faFileAlt,
  faCloudUploadAlt,
  faCamera

} from '@fortawesome/free-solid-svg-icons'

import {faCalendarAlt as faCalendarAltRegular} from '@fortawesome/free-regular-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faCircleNotch,
  faBuilding,
  faUsers,
  faCogs,
  faGlobeAsia,
  faSearchLocation,
  faRoad,
  faMapMarkerAlt,
  faEdit,
  faTrashAlt,
  faPowerOff,
  faCar,
  faCarSide,
  faTruckMoving,
  faTruckPickup,
  faTrailer,
  faPalette,
  faGasPump,
  faUserFriends,
  faTools,
  faExclamationTriangle,
  faPlusCircle,
  faUser,
  faPhoneSquareAlt,
  faPassport,
  faTimesCircle,
  faAd,
  faCalendarCheck,
  faBarcode,
  faListOl,
  faExchangeAlt,
  faCheckDouble,
  faClipboardList,
  faTimes,
  faCheckCircle,
  faPlus,
  faDotCircle,
  faSave,
  faFileWord,
  faPrint,
  faCalendarAlt,
  faSignInAlt,
  faCalendarAltRegular,
  faCarCrash,
  faBurn,
  faQrcode,
  faKey,
  faUserEdit,
  faSortNumericUp,
  faChartArea,
  faHistory,
  faDownload,
  faBan,
  faInfoCircle,
  faSearch,
  faEnvelope,
  faCode,
  faTerminal,
  faBars,
  faHome,
  faMapSigns,
  faPhoneAlt,
  faMoneyCheckAlt,
  faCreditCard,
  faPortrait,
  faFolderPlus,
  faPaperclip,
  faCheck,
  faBell,
  faPaste,
  faUndo,
  faUserCog,
  faSearchPlus,
  faPlusSquare,
  faBackspace,
  faQuestionCircle,
  faReceipt,
  faSearchDollar,
  faSearchMinus,
  faTabletAlt,
    faLaptopCode,
    faLink,
    faSortNumericDown,
    faFileAlt,
    faCloudUploadAlt,
    faCamera
)

Vue.component('fa-icon', FontAwesomeIcon)
